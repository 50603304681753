export const SOLANA_NETWORK = process.env.NEXT_PUBLIC_SOLANA_NETWORK || 'mainnet'
export const SERVER_URL = process.env.NEXT_PUBLIC_SERVER_URL || 'https://api.thestonedfrogs.io'

export const FRONT_END_URL = "https://www.puffsterzpad.io";
export const CLIENT_ID = "84e99369-aac3-4fbc-a888-407ad1bf1b69";

export const CLIENT_NAME = "Puffsterz";
export const PROJECT_NAME = "PuffsterzPad";
export const TWITTER_USERNAME = "puffsterznfts";
export const GTAG_ID = "G-HZGH8QMWHG";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyBbC8k4NgSxoY5DsCrNq6Epj5RgBTnl8OI",
  authDomain: "puffsterz.firebaseapp.com",
  projectId: "puffsterz",
  storageBucket: "puffsterz.appspot.com",
  messagingSenderId: "11524643663",
  appId: "1:11524643663:web:01cae3f5c94bb6e33ec33a",
  measurementId: "G-HZGH8QMWHG"
}

export const COLORS = {
  light: {
    primary: "white",
    secondary: "black",
    background: '#111827',
  },
  dark: {
    primary: "gray",
    secondary: "white",
    background: '#111827',
  },
};
export const S3_FOLDER_PATH =
  "https://s3.us-west-1.amazonaws.com/cdn.thestonedfrogs.io/_assets/puffsterz/";

export const EXTRA_MENU = [
];

